<template>
  <div class="home">
    <div class="big-img">
      <img
        :src="this.$qiniuUrl + 'home/home_new.jpg'"
        @click="examination"
        alt
        srcset
      />
      <!-- <div class="toExam"></div> -->
    </div>

    <div class="what">
      <div class="part">
        <el-row :gutter="22">
          <el-col :span="12">
            <img
              class="whatImg"
              :src="this.$qiniuUrl + 'home/img1.png'"
              alt
              srcset
            />
          </el-col>
          <el-col :span="12">
            <h2 class="part-title">什么是网培师</h2>
            <p
              v-for="(item, index) in brief"
              :key="index"
              class="part-cont spacing"
            >
              {{ item }}
            </p>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="become">
      <div class="part-bg">
        <div class="part">
          <h2 class="part-title">为什么成为网培师</h2>
          <el-row :gutter="20" type="flex">
            <el-col :span="12">
              <el-card class="box-card">
                <div class="flex-col-center">
                  <img
                    class="icon"
                    src="../assets/images/home/Icon1.png"
                    alt
                    srcset
                  />
                  <strong>就业前景好</strong>
                </div>
                <p>
                  国内首家对在线教育岗位的认证平台，与多家名企机构和院校合作，面向国内的在线教育培训工作者提供技能培训、评测认证、上岗就业等系列服务。被称为网络教学的必备证书！
                </p>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <div class="flex-col-center">
                  <img
                    class="icon"
                    src="../assets/images/home/Icon2.png"
                    alt
                    srcset
                  />
                  <strong>全面教学能力和实操技能提升</strong>
                </div>
                <p>
                  网培师认证评测内容包括：教学综合能力评测、网培政策规范评测、教学数字化能力评测；学习期间可培养个人的教学能力，网培相关政策法规能力及制作教学课件的能力。
                </p>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="20" type="flex">
            <el-col :span="12">
              <el-card class="box-card">
                <div class="flex-col-center">
                  <img
                    class="icon"
                    src="../assets/images/home/icon3 (2).png"
                    alt
                    srcset
                  />
                  <strong>一体化颁证流程</strong>
                </div>
                <p>
                  了解岗位、报名付费、模拟考试练习、在线考试、获得证书，本平台提供一体化的整个流程服务！
                  本平台为学员提供岗位咨询、学习资料、模拟练习、远程考试、颁发证书和工作推荐等，我们立志为学员打造最完善的服务！
                </p>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card">
                <div class="flex-col-center">
                  <img
                    class="icon"
                    src="../assets/images/home/icon4 (2).png"
                    alt
                    srcset
                  />
                  <strong>国际认证</strong>
                </div>
                <p>
                  网培师能力水平评价项目由中国商业联合会和上海现代服务业联合会互联网科创服务专委会分别批准，并得到联合国训研所UNITAR中国中心的认可并达成正式合作。
                </p>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <div class="advantage">
      <div class="part">
        <h2 class="part-title text-center">网培师证书</h2>
        <el-row>
          <el-col :span="8" :push="16">
            <img
              :src="this.$qiniuUrl + 'home/certProfessional.jpg'"
              alt
              srcset
              class="certificate"
            />
          </el-col>
          <el-col :span="16" :pull="8">
            <div class="part-certificate">
              <el-card class="card-cf">
                <div slot="header">
                  <span>证书优势</span>
                  <div class="br"></div>
                </div>
                <div>
                  <p>
                    1.&nbsp;评审通过后，本证书将由“中国商业联合会"颁发。
                  </p>
                  <p>
                    2.&nbsp;本证书表明，持有者通过岗位能力评测并考核合格，具备了相应的专业知识和技能，可作为聘用、考核和能力评价的参考依据，国内通用。
                  </p>
                  <p>
                    3.&nbsp;本证书序列号具有唯一性，并基于教育区块链学习银行记录证书，此证书具有数据安全可信、不可篡改等特点，且易于携带、保存和转发等特点。
                  </p>
                  <p>
                    4.&nbsp;网培师项目由上海市培训协会、上海人才服务行业协会、上海现代服务业联合会互联网科创专委会和网班教育评测中心等共同发起和组织，并由中国商业联合会在国内设立的专业能力评价认证项目。
                  </p>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="fit">
      <div class="part part3">
        <h2 class="part-title text-center">网培师适合人群</h2>
        <el-row :gutter="60">
          <el-col :span="12">
            <div class="flex-col-center part3-cont">
              <img :src="this.$qiniuUrl + 'home/img2.png'" alt srcset />
              <p>从事在线教育的培训师，教师，教学辅导人员等。</p>
            </div>
            <div class="flex-col-center part3-cont">
              <img :src="this.$qiniuUrl + 'home/img3.png'" alt srcset />
              <p>在线教育行业的专业管理人员、企业管理人员。</p>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="flex-col-center part3-cont">
              <img :src="this.$qiniuUrl + 'home/img4.png'" alt srcset />
              <p>希望将来能从事在线教育领域工作，并取得执业发展的学生。</p>
            </div>
            <div class="flex-col-center part3-cont">
              <img :src="this.$qiniuUrl + 'home/img5.png'" alt srcset />
              <p>希望时间自由、利用网络分享知识的新阶层人士。</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- <div class="part part-text">
      <p
        class="part-cont part-indent"
      >随着在线经济的蓬勃发展，特别是后疫情时代，在线教育的需求得到催化和爆发，急需建立相关从业人员的行业从业标准，提升从业人员的执业能力。</p>
      <p
        class="part-cont part-indent"
      >网培师能力水平评价项目由中国商业联合会和上海现代服务业联合会互联网科创服务专委会分别批准，面向广大的在线教育培训从业人员提供网络培训能力的测评认证。项目采用网班教育的《网培师教育培训及评测标准》体系，并与上海市人才服务行业协会等头部教培机构合作，面向全国的在线教育培训工作者提供技能培训、评测认证、上岗就业等系列服务。</p>
      <p
        class="part-cont part-indent"
      >网培师项目并得到联合国训研所UNITAR中国中心的认可并达成正式合作，参与培训及认证的网培师将同时进入联合国训研所的官方人才库。</p>
      <img src="../assets/images/home/icon4.png" alt srcset />
    </div>-->

    <!-- <div class="comment">
      <h2 class="text-center">学员评价</h2>
      <div class="comment_part">
        <el-carousel :interval="50000">
          <el-carousel-item v-for="(item, index) in comments" :key="index">
            <el-row :gutter="20" type="flex">
              <el-col :span="12">
                <el-card class="box-card">
                  <div class="flex-col-center comment_box">
                    <div class="comment_img">
                      <img :src="item[0].img" alt="" />
                    </div>
                    <p>{{ item[0].comment }}</p>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <div class="flex-col-center comment_box">
                    <div class="comment_img">
                      <img :src="item[1].img" alt="" />
                    </div>
                    <p>{{ item[1].comment }}</p>
                  </div>
                </el-card>
              </el-col>
            </el-row>
            <el-row :gutter="20" type="flex">
              <el-col :span="12">
                <el-card class="box-card">
                  <div class="flex-col-center comment_box">
                    <div class="comment_img">
                      <img :src="item[2].img" alt="" />
                    </div>
                    <p>{{ item[2].comment }}</p>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <div class="flex-col-center comment_box">
                    <div class="comment_img">
                      <img :src="item[3].img" alt="" />
                    </div>
                    <p>{{ item[3].comment }}</p>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div> -->

    <div class="background">
      <div class="part">
        <el-row>
          <el-col :span="11">
            <div class="part-offset">
              <el-card>
                <h2 class="part-title">网培师项目背景</h2>
                <p class="part-cont part-indent">
                  2019年9月30日，《关于促进在线教育健康发展的指导意见》，明确指出，到2020年，大幅提升在线教育的基础设施建设水平，互联网、大数据、人工智能等现代信息技术在教育领域的应用更加广泛、在线教育模式更加完善，资源和服务更加丰富。到2022年，现代信息技术与教育实现深度融合，在线教育质量不断提升，资源和服务标准体系全面建立，学习型社会建设取得重要进展。
                </p>
                <p class="part-cont part-indent">
                  2020年7月，由人力资源社会保障部联合市场监管总局、统计局近日正式向社会发布一批新职业，包括：“在线学习服务师”、“互联网营销师”、“区块链工程技术人员”、“老年人能力评估师”等9个新职业。这是我国自《中华人民共和国职业分类大典（2015年版）》颁布以来发布的第三批新职业。
                </p>
                <div class="biao text-right">
                  <img src="../assets/images/home/icon3.png" alt srcset />
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="13">
            <img
              :src="this.$qiniuUrl + 'home/img6.jpg'"
              alt
              srcset
              class="backimg"
            />
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- <div class="part-bg">
      <div class="part">
        <h2 class="part-title text-center">网培师评测认证体系</h2>
        <el-row :gutter="20" type="flex">
          <el-col :span="11">
            <el-card>
              <div class="flex-col-center">
                <img
                  class="icon"
                  src="../assets/images/home/Icon1.png"
                  alt
                  srcset
                />
                <strong>网培师</strong>
              </div>
              <p class="part-cont">
                1.坚持社会主义核心价值观，充分了解国家在互联网管理及互联网教学培训管理方面的相关政策和法规。
                <br />2.有较强的沟通能力，具备基本的课堂教学或教学辅导素养，对自然科学及社会人文学科知识有基本的了解。
                <br />3.具有教育学、心理学和网络教学的基础知识，具备所教领域的专业知识，能够独立依据教学大纲及教材，正确传授知识和技能，教学效果良好。
                <br />4.熟练掌握教学课件的制作和演示，并能在网络教学中熟练应用。有一定的在线课程制作及发布经验。
                <br />5. 掌握常用的数字化教学工具软件的使用方法。 <br />6.
                通过网培师在线评测系统相应个模块的能力评测。
              </p>
            </el-card>
          </el-col>
          <el-col :span="13">
            <el-card>
              <div class="flex-col-center">
                <img
                  class="icon"
                  src="../assets/images/home/Icon2.png"
                  alt
                  srcset
                />
                <strong>网培师(高级)</strong>
              </div>
              <p class="part-cont">
                1.
                坚持社会主义核心价值观，充分了解国家在互联网管理及互联网教学培训管理方面的相关政策和法规，并长期遵照相关法规执行。
                <br />2. 已完成“网培师”阶段的课程学习评测认证。 <br />3.
                具有正确教育学生的能力，能够根据网络班级的教学场景、学生年龄段、以及学生的思想实际进行教学，有丰富的一对多在线课堂的教学工作经验，并较好地完成任务。
                <br />4.
                对所教领域有比较扎实的基础理论和专业知识，独立掌握所教课程的课程标准、教材、教学原则和教学方法，教学经验丰富，有较强的专业知识技能，教学效果好。
                <br />5.
                具有较强的组织和开展教育教学研究的能力，并曾承担一定的教学研究任务，做出具有创新性的教学课件。
                <br />6.
                在培养、指导同行网培师提高业务水平和教育教学能力方面曾做出一定成绩。
                <br />7.
                具有三年及以上行业工作经验，累计网络授课课时数在三千小时以上，或者制作的录播课程在120课时以上且课程视频累计播放量达到一万以上，普通话水平达到二级甲等。
              </p>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div> -->
    <div class="evaluating">
      <div class="part">
        <h2 class="part-title text-center">在线评测模块</h2>
        <el-card>
          <el-row :gutter="40">
            <el-col :span="8" v-for="item in exam" :key="item.url">
              <img :src="item.url" alt srcset />
              <h3 v-text="item.title" class="part-h3"></h3>
              <p class="part-cont" v-text="item.desc"></p>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </div>

    <div class="news">
      <el-row class="cont">
        <el-col :span="12" class="News">
          <el-row>
            <el-col :span="16">
              <h3 class="tit2">行业要闻</h3>
            </el-col>
            <el-col :span="8">
              <p @click="more" class="text-right link">更多>></p>
            </el-col>
          </el-row>
          <div class="xian"></div>
          <div class="xw" v-for="(item, index) in Newslist" :key="index">
            <el-row>
              <el-col :span="16">
                <p class="contp" @click="newsInfo(item.id, item.type)">
                  <i></i>{{ item.name }}
                </p>
              </el-col>
              <el-col :span="8">
                <p class="text-right source">{{ item.push_date }}</p>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="12" class="Notice">
          <el-row>
            <el-col :span="16">
              <h2 class="tit2">平台公告</h2>
            </el-col>
            <el-col :span="8">
              <p @click="more" class="text-right link">更多>></p>
            </el-col>
          </el-row>
          <div class="xian"></div>
          <div class="xw" v-for="(item, index) in Noticelist" :key="index">
            <el-row>
              <el-col :span="16">
                <p class="contp" @click="newsInfo(item.id, item.type)">
                  <i></i>{{ item.name }}
                </p>
              </el-col>
              <el-col :span="8">
                <!-- <p class="text-right source">{{item.updated_at.substring(0,item.updated_at.length-3)}}</p> -->
                <p class="text-right source">{{ item.push_date }}</p>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
let _this
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      brief: [
        '网培师，指依托互联网平台从事培训咨询和教学活动的人员，是在推进在线教育发展及推出在线教育相关新职业岗位的背景下，由中国商业联合会在国内设立的专业能力评价认证项目。网培师的标准建设及平台服务，由上海及相关省市的培训协会、人才服务行业协会、以及网班教育等多家专业组织和机构共同参与，受到众多教培机构的欢迎。',
        '随着在线经济的蓬勃发展，特别是后疫情时代，在线教育的需求得到催化和爆发，急需建立相关从业人员的职业水平标准和管理规范，提升在线教育培训从业人员的专业水平和执业能力。网培师能力水平评价项目面向广大的在线教育培训领域的从业人员，进行教学综合素质、网络政策法规、教学数字化水平等相关模块的能力测评和认证，综合成为网培师能力认证。项目基于T/SSF-SHRCA 001-2020 网培师能力评测标准及相关的培训体系，面向国内的在线教育培训工作者提供技能培训、评测认证、上岗就业支持等系列服务。',
        '网培师能力评测标准和体系的建设，得到了国内主流教育培训机构的广泛响应和参与。相关的培训及评测认证工作，也为希望从事在线培训行业的人才提供了一个职业发展的良好平台。',
        '网培师项目并与联合国训研所UNITAR中国中心等国际机构建立了协作关系，符合条件的网培师可进一步申请获得联合国训研所的相关证书并进入其官方人才库。',
      ],
      exam: [
        {
          url: this.$qiniuUrl + 'home/img7.png',
          title: '教学综合能力评测',
          desc:
            '本模块旨在考核被评测者是否具备担任培训教学综合能力，评测标准与教师资格证近似。通过科学的命题手段和方法，对被评测者的教学基础知识掌握情况以及在案例中的应用熟练程度进行测量和评定，促使被评测者在教学能力与相关知识水平上有进一步的提升；本评测还能协助用人单位完成教学人员的选拔、晋升，用于员工培训发展等工作。',
        },
        {
          url: this.$qiniuUrl + 'home/img8.png',
          title: '网培政策规范评测',
          desc:
            '本模块旨在考核被评测者是否了解和掌握了在我国从事网络培训工作需要具备的相关政策法规，涉及到计算机网络安全、知识产权、文明上网等相关法规。通过本模块的评测，促使网络培训行业的从业人员重视政策法规在网络培训中的重要地位，提升自身行为的合法合规水平，进而提升整个在线教育培训领域的政策规范水平，促进行业的健康发展。',
        },
        {
          title: '教学数字化能力评测',
          url: this.$qiniuUrl + 'home/img9.png',
          desc:
            '本模块旨在考核被评测者的在线教学能力及数字化工具使用的水平，其中在线教学包括网络教学方法、在线辅导课、网络直播课、网络录播课和网络教学质量的控制与保障。数字教学工具又分别为PPT和WPS、Ps、视频剪辑工具等使用水平。通过本评测，也能提升从业人员对在线教学和工具的掌握程度，进一步提升整个行业的教学数字化水平。',
        },
      ],
      Newslist: [],
      Noticelist: [],
      comments: [
        [
          {
            img: require('../assets/images/home/headportraits/headportrait1.png'),
            comment: '课程还行，刷题功能蛮好的很流畅。',
          },
          {
            img: require('../assets/images/home/headportraits/headportrait2.png'),
            comment:
              '学会了一些课件制作的技巧，还有网络教学过程中要注意的政策规范。',
          },
          {
            img: require('../assets/images/home/headportraits/headportrait3.png'),
            comment: '因为读了教师资格，迎合目前需求，再增加点线上的教学技能。',
          },
          {
            img: require('../assets/images/home/headportraits/headportrait4.png'),
            comment:
              '第一次只看了课件，没刷题考不出来，第二次刷了题就容易很多。',
          },
        ],
        [
          {
            img: require('../assets/images/home/headportraits/headportrait5.png'),
            comment: '第一个模块，就是我考教师资格证的题目。',
          },
          {
            img: require('../assets/images/home/headportraits/headportrait6.png'),
            comment: '这个课缺少教学方法，就觉得学到些政策法规。',
          },
          {
            img: require('../assets/images/home/headportraits/headportrait7.png'),
            comment:
              '早知道在考教师资格证前先来考这个，刷刷题对考教师资格蛮有用的，如果读过教师资格证了读这个意义好像不大。除非为了多张证',
          },
          {
            img: require('../assets/images/home/headportraits/headportrait8.png'),
            comment: '这个课程能不能做得再精致点，感觉教程没问题，但是有点乱。',
          },
        ],
        [
          {
            img: require('../assets/images/home/headportraits/headportrait9.png'),
            comment: '想通过考试还是主要靠刷题，光看课程没什么用。',
          },
          {
            img: require('../assets/images/home/headportraits/headportrait10.png'),
            comment:
              '和教师资格证有点重合，而且含金量不如教师资格证，但是容易点，如果教资考不上，先学这个打点基础也不错。',
          },
          {
            img: require('../assets/images/home/headportraits/headportrait11.png'),
            comment: '课程里有教剪辑和修图的教学，还蛮有意思的，能提升技能。',
          },
          {
            img: require('../assets/images/home/headportraits/headportrait12.png'),
            comment: '如果想从事网络在线教学的话，可以增加在线教学知识。',
          },
        ],
      ],
    }
  },
  created() {
    _this = this
    if (_this.$route.params.agent) {
      document.cookie = 'agent=' + escape(_this.$route.params.agent.trim())
    }
    this.init()
  },
  methods: {
    examination() {
      this.$router.push({ path: '/traincourse' })
      // if (this.$store.state.wps_user.mobile) {
      //   this.$router.push({ path: '/userCenter/info' })
      // } else {
      //   this.$router.push({ path: '/login' })
      // }
    },
    init() {
      _this.$http
        .get('http://api.wangpeishi.org.cn/api/article')
        .then(data => {
          if (data.data.code == 200) {
            var list = data.data.data
            for (var i = 0; i < list.length; i++) {
              if (list[i].type == 2) {
                _this.Newslist.push(list[i])
              } else {
                _this.Noticelist.push(list[i])
              }
            }
            _this.Newslist = _this.Newslist.slice(0, 5)
            _this.Noticelist = _this.Noticelist.slice(0, 5)
            //排序方法
            const sortRule = function(a, b) {
              return b.push_date < a.push_date ? -1 : 1
            }
            _this.Newslist.sort(sortRule)
            _this.Noticelist.sort(sortRule)
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    newsInfo(id, type) {
      this.$router.push({
        path: '/newsDesc',
        query: {
          id: id,
          type: type,
        },
      })
    },
    more() {
      this.$router.push({
        path: '/news',
      })
    },
  },
}
</script>
<style lang="less" scoped>
.home {
  font-weight: normal;
  img {
    width: 100%;
  }
  .big-img {
    width: 100%;
    position: relative;
    img {
      cursor: pointer;
    }
  }
  .part {
    width: 70%;
    min-width: 1200px;
    margin: 0 auto;
    position: relative;
    .el-button {
      line-height: 1.8;
    }
    /deep/.el-card .el-card__header {
      border-bottom: none;
    }
    /deep/.el-card .el-card__body {
      .el-col.el-col-4 {
        padding-right: 10px !important;
        img {
          border: 1px #fbf8f5 solid;
        }
      }
      .el-col.el-col-20 {
        padding-left: 0px !important;
      }
    }

    .certificate {
      width: 410px;
      height: 545px;
      z-index: 90;
      position: relative;
      top: 60px;
    }
    @media screen and (max-width: 1849px) {
      .certificate {
        height: 575px;
      }
    }
    .part-h3 {
      font-size: 25px;
      padding: 26px 0;
      text-align: center;
    }
    .part-title {
      font-size: 36px;
      font-weight: bold;
      color: #000005;
      line-height: 24px;
      padding-bottom: 20px;
    }
    .part-cont {
      padding-top: 14px;
      font-size: 15px;
      font-weight: 400;
      color: #333;
      line-height: 34px;
    }
    // .part-indent {
    //   text-indent: 20px;
    // }

    .el-row.el-row--flex {
      padding-bottom: 30px;
    }
    .part-offset {
      padding-left: 20px;
      border-radius: 4px;
      background: #960f23;
      margin-top: 10px;
      .part-title {
        padding-bottom: 26px;
      }
      /deep/.el-card .el-card__body {
        padding: 40px 60px 85px 20px;
        padding-bottom: 115px;
        .biao {
          position: absolute;
          z-index: 1000;
          bottom: 20px;
          // width: 100%;
          img {
            width: 750px;
          }
        }
      }
    }
    .icon {
      width: 46px;
    }
    strong {
      color: #000005;
      padding: 30px;
      font-size: 25px;
    }
    .text-center {
      padding-bottom: 60px;
    }
  }
  .part-bg {
    width: 100%;
    background: url('../assets/images/home/part-bg.jpg');
    background-size: 100% 100%;
    padding-bottom: 40px;

    .el-card {
      height: 100%;
      box-sizing: border-box;
      padding: 0 0px;
    }
    strong {
      font-size: 26px;
      font-weight: 400;
      color: #333333;
      padding: 18px 15px 23px;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      color: #333;
      line-height: 30px;
    }
    .el-card .el-card__body {
      padding: 30px;
    }
  }
  .part-text {
    padding-left: 150px;
    padding-right: 150px;
    img {
      margin: 60px 0;
    }
  }
  .part3 {
    img {
      width: 210px;
    }
    .el-row {
      padding-top: 20px;
    }
    .part3-cont {
      border: 1px solid #ce9a6c;
      padding: 40px;
      margin-bottom: 35px;
    }
    .part3-title {
      padding-bottom: 100px;
    }
    p {
      color: #333333;
      font-size: 20px;
      padding-left: 10px;
    }
  }
  .part-certificate {
    margin-top: 75px;
    .el-card .el-card__header {
      padding: 40px 60px 85px 20px;
    }
    span {
      font-size: 26px;
      font-weight: 400;
      color: #333333;
      line-height: 42px;
    }
    .br {
      width: 62px;
      height: 4px;
      background: #7b0b07;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      color: #333;
      line-height: 30px;
      padding-bottom: 45px;
    }
  }
  .toExam {
    width: 160px;
    height: 53px;
    // background: #ccc;
    position: absolute;
    top: 490px;
    left: 280px;
    cursor: pointer;
  }
  .news {
    width: 100%;
    padding: 40px 0px;
    background: #fbf8f5;
    border-radius: 0px 0px 5px 5px;
    .tit1,
    .tit2 {
      padding-left: 8px;
      border-left: 5px solid #960f23;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000005;
    }
    .tit1 {
      margin: 0px 0px 10px 0px;
    }
    .tit2 {
      margin: 0px 0px 10px 0px;
    }
    .xian {
      height: 1px;
      background-color: lightgray;
      margin-bottom: 35px;
    }
    .link {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }
    .cont {
      width: 70%;
      min-width: 1200px;
      margin: 0px auto;
      line-height: 30px;
      i {
        height: 8px;
        width: 8px;
        background-color: #000000;
        border-radius: 50%;
        display: inline-block;
        margin: 0px 5px 1px 0px;
      }
      .News,
      .Notice {
        background-color: #ffffff;
        padding: 45px 45px 25px 45px;
        border-radius: 3px;
      }
      .News {
        border-right: 10px solid #fbf8f5;
      }
      .Notice {
        border-left: 10px solid #fbf8f5;
      }
    }
    .contp {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 30px;
    }
    .cont p:hover {
      cursor: pointer;
    }
    p {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .source {
      font-size: 14px;
      color: #666666;
    }
    .newcont {
      margin: 0px;
    }
    .xw {
      padding: 0px 0px 1px 0px;
      .xw_img {
        width: 120px;
        img {
          width: 100%;
        }
      }
      .xw_txt {
        margin-left: 10px;
      }
      .el-row {
        margin: 10px 0px !important;
      }
      span {
        color: #e10133;
      }
    }
  }
  .what {
    padding-top: 88px;
    padding-bottom: 50px;
    // .part{
    //   .el-row{
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //   }
    // }
    .whatImg {
      height: 570px;
    }
    @media screen and (max-width: 1840px) {
      .whatImg {
        height: 605px;
        object-fit: cover;
      }
    }
  }
  .become {
    padding-top: 30px;
    .part-title {
      padding: 70px 0px 75px;
      text-align: center;
    }
    /deep/.el-card {
      box-shadow: none;
      border: none;
    }
  }
  .advantage {
    padding: 80px 0px 95px;
    .part-title {
      padding-bottom: 30px;
    }
  }
  .fit {
    background: #fbf8f5;
    padding: 88px 0px 70px;
    .part-title {
      padding-bottom: 60px;
    }
  }
  .comment {
    padding: 80px 0px 50px;
    .comment_part {
      width: 70%;
      min-width: 1200px;
      margin: 0 auto;
      position: relative;
      .el-row.el-row--flex {
        padding: 0px 8px 30px;
      }
      /deep/.el-card {
        border-radius: 6px;
        box-shadow: 0px 0px 10px -1px rgb(0 0 0 / 8%);
        border: none;
      }
      /deep/.el-card__body {
        padding: 30px 20px !important;
      }
    }
    h2 {
      font-size: 36px;
      font-weight: bold;
      color: #000005;
      line-height: 24px;
      margin-bottom: 80px;
    }
    // /deep/.el-carousel__arrow{
    //   display: none;
    // }
    /deep/.el-carousel__indicators {
      display: none;
    }
    .comment_box {
      height: 70px;
      .comment_img {
        width: 70px;
        height: 70px;
        border-radius: 50px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50px;
        }
      }
      p {
        margin-left: 20px;
        width: 80%;
      }
    }
  }
  .background {
    background-color: #fbf8f5;
    .part-cont {
      line-height: 30px;
    }
    .backimg {
      height: 585px;
    }
    @media screen and (max-width: 1840px) {
      .backimg {
        height: 615px;
      }
    }
    padding: 100px 0px 50px;
  }
  .evaluating {
    padding: 70px 0px 60px;
    h3.part-h3 {
      padding-bottom: 0px;
    }
  }
}
</style>
