<template>
  <div class="layout">
    <div class="nav">
      <!-- <div class="nav-status flex-row-between">
        <span><a href="tel:17721037605">客服热线：17721037605</a></span>
        <el-breadcrumb separator="|" class="text-right">
          <el-breadcrumb-item>
            <a href="/">首页</a>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <a href="/traincourse">培训课程</a>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <a href="/simulation">模拟练习</a>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <a href="/examination">参加考试</a>
          </el-breadcrumb-item>
          <el-breadcrumb-item v-if="false">
            <a href="/micrologin">推荐人登录</a>
          </el-breadcrumb-item>
          <el-breadcrumb-item v-if="signupshow">
            <a
              @click="tosignup"
              style="color:rgb(64, 158, 255)!important;cursor:pointer;"
              >立即报名</a
            >
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div> -->
      <div class="nav-logo">
        <span @click="toHome"
          ><img src="../../assets/images/user/logo.png" alt srcset
        /></span>
      </div>
      <div class="nav-status flex-row-between">
        <el-breadcrumb separator="|" class="text-right">
          <el-breadcrumb-item>
            <a href="/">首页</a>
          </el-breadcrumb-item>
          
          <el-breadcrumb-item>
            <a href="/traincourse">培训课程</a>
          </el-breadcrumb-item>

          <el-breadcrumb-item>
            <a href="/simulation">模拟练习</a>
          </el-breadcrumb-item>

          <el-breadcrumb-item>
            <a href="/examination">参加考试</a>
          </el-breadcrumb-item>

          <el-breadcrumb-item v-if="agent">
            <a @click="toagent">代理列表</a>
          </el-breadcrumb-item>

          <el-breadcrumb-item v-if="signupshow">
            <a
              @click="tosignup"
              style="color:rgb(64, 158, 255)!important;cursor:pointer;"
              >立即报名</a
            >
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="main">
      <el-container>
        <el-aside>
          <el-menu
            :default-active="$route.path"
            class="navbar"
            mode="vertical"
            :router="true"
            :unique-opened="true"
          >
            <el-submenu index="1" default-openeds="1">
              <template slot="title">个人资料</template>
              <el-menu-item index="/usercenter/info">我的资料</el-menu-item>
              <el-menu-item index="/usercenter/resetpassword"
                >修改密码</el-menu-item
              >
              <el-menu-item index="/usercenter/resetmobile"
                >修改手机号</el-menu-item
              >
            </el-submenu>

            <!-- <el-submenu index="8">
              <template slot="title">进入评测</template>
              <el-menu-item index="/exam/1">教学综合能力评测</el-menu-item>
              <el-menu-item index="/exam/2">网培政策规范评测</el-menu-item>
              <el-menu-item index="/exam/3">教学数字化能力评测</el-menu-item>
            </el-submenu> -->

            <el-submenu index="8">
              <template slot="title">预约时间</template>
              <el-menu-item index="/usercenter/appointment"
                >预约时间</el-menu-item
              >
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">我的成绩</template>
              <el-menu-item index="/usercenter/score">评测成绩</el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">我的证书</template>
              <el-menu-item index="/usercenter/certs">我的证书</el-menu-item>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">高级网培师</template>
              <el-menu-item index="/usercenter/apply"
                >高级网培师申请</el-menu-item
              >
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">我的作品</template>
              <el-menu-item index="/usercenter/workslist"
                >个人上传作品列表</el-menu-item
              >
            </el-submenu>
            <el-submenu index="6">
              <template slot="title">支付记录</template>
              <el-menu-item index="/usercenter/paylist"
                >我的支付记录</el-menu-item
              >
            </el-submenu>
            <el-menu-item index="7" @click="logout"
              ><span>退出</span></el-menu-item
            >
          </el-menu>
        </el-aside>
        <el-main class="content">
          <router-view></router-view>
        </el-main>
      </el-container>
    </div>
    <!-- <div class="footer">
      <el-row type="flex">
        <el-col :span="10">
          <img src="../../assets/images/home/logo.png" alt srcset />
        </el-col>
        <el-col :span="3">
          <p>
            <strong>项目介绍</strong>
            <el-divider></el-divider>
          </p>
          <p>
            <a
              href="http://resource.wangpeishi.org.cn/evaluation-standard.pdf"
              target="_blank"
              >行业标准</a
            >
          </p>
          <p>
            <a href="http://www.wangpeishi.org.cn/certinfo">认证机构</a>
          </p>
        </el-col>
        <el-col :span="3">
          <p>
            <strong>认证体系</strong>
            <el-divider></el-divider>
          </p>
          <p>
            <a href="http://www.wangpeishi.org.cn/certificate">网培师</a>
          </p>
          <p>
            <a href="http://www.wangpeishi.org.cn/gcertificate">高级网培师</a>
          </p>
        </el-col>
        <el-col :span="8">
          <p>
            <strong>联系我们</strong>
            <el-divider></el-divider>
          </p>
          <p>
            <a href="http://www.wangpeishi.org.cn/question"
              >网培师网站QA常见问题</a
            >
          </p>
          <p>
            <a href="tel:021-61806577-8005">021-61806577-8005</a>
          </p>
          <p>
            <a href="email: office@netban.cn">office@netban.cn</a>
          </p>
          <p>上海市静安区万荣路1188号A座龙软大厦6楼</p>
        </el-col>
      </el-row>
      <div class="text-center beian">
        <p>中国商业联合会 . 全国职业水平评价项目</p>
        <p style="margin:5px 0 15px 0;">
          <span>2020 网培师评测认证中心</span
          ><span style="margin-left:15px;">All Rights Reserved</span>
        </p>
        <a href="https://beian.miit.gov.cn"> 沪ICP备10203489号-52 </a>
      </div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Layout',
  components: {},
  data() {
    return {
      name: '',
      show: 'info',
      signupshow: '',
      agent: '',
    }
  },
  mounted() {},
  created() {
    // this.name = this.$store.state.wps_user.name
    let _this = this
    _this.agent = _this.Storage.getStorage('agent')
    console.log(this.agent)
    _this.$http
      .post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
      .then(data => {
        this.token = data.data.token
        if (data.data.code == 403) {
          _this.$message.error(data.data.msg)
          return
        }
        if (data.data.code == 200) {
          _this.signupshow = true
        } else {
          _this.signupshow = false
        }
      })
      .catch(err => {
        _this.$message.error('网络错误，请重试')
        console.log(err)
      })
  },
  methods: {
    logout() {
      let _this = this
      _this.$http
        .post(process.env.VUE_APP_URL + 'logout')
        .then(function(data) {
          if (data.code == 403 || data.code == 500) {
            _this.$message.error(data.data.msg)
            return
          }
          _this.$store.commit('setUser', {})
          _this.Storage.delStorage('token')
          _this.Storage.delStorage('agent')
          window.location.href = '/'
          return
        })
        .catch(function(error) {
          console.log(error)
          _this.$message.error('网络错误,请刷新界面重试')
          return
        })
    },
    toHome() {
      this.$router.push({ path: '/' })
    },
    tosignup() {
      this.$router.push({
        name: 'Traincourse',
        params: {
          signStore: this.signupshow,
        },
      })
    },
    toagent() {
      var Days = 30
      var exp = new Date()
      exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
      document.cookie =
        'AgentCode=' +
        escape(this.agent.numbers.trim()) +
        ';expires=' +
        exp.toUTCString()
      window.open('/microlist')
      // this.$router.push({ name: "MicroList" });
      // this.$router.push({
      //   name: 'MicroLogin',
      //   params: {
      //     number:this.agent.numbers
      //   },
      // })
    },
  },
}
</script>
<style lang="less" scoped>
@import '../../common/css/base.css';
@import '../../common/css/common.less';
.nav {
  min-width: 1200px;
  position: fixed;
  width: 100%;
  // height: auto;
  background: #960f23;
  overflow-y: auto;
  top: 0;
  z-index: 999;
  a {
    cursor: pointer !important;
  }
  display: flex;
}
.nav-status {
  width: 100%;
  // margin: 0 auto;
  // font-size: 16px;
  // padding: 15px 15%;
  // background: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  box-sizing: border-box;
  position: relative;
  a {
    color: #fff !important;
    font-weight: normal !important;
  }
  /deep/.el-breadcrumb {
    font-size: 18px;
    margin-right: 40px;
    .el-breadcrumb__inner {
      color: #fff !important;
    }
    .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
      color: #fff !important;
    }
  }
  /deep/.navbar {
    border-bottom: none !important;
    .el-submenu__title {
      padding: 0 10px !important;
      color: #333 !important;
      font-size: 16px;
      i {
        display: none;
      }
    }
    .el-menu-item {
      font-size: 16px;
      color: #333 !important;
    }
  }
}
.nav-logo {
  background: #960f23;
  padding: 20px 15% 10px;
  box-sizing: border-box;
}
.menu {
  font-size: 16px;
  color: #7c8087;
  li {
    padding-top: 20px;
    cursor: pointer;
  }
  .menu-title {
    color: #333;
    font-weight: bolder;
    padding-top: 50px;
  }
}
.main {
  width: 60%;
  min-width: 1100px;
  margin: 85px auto 100px;
  .el-aside {
    width: 250px !important;
    position: fixed;
    background: #fff;
    z-index: 999;
  }
}
.footer {
  width: 100%;
  height: 350px;
  padding: 40px 0;
  background: #2b2b2b;
  box-sizing: border-box;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  .el-row {
    width: 70%;
    min-width: 1200px;
    margin: 0 auto;
    img {
      margin-top: 90px;
    }
    p {
      padding-bottom: 10px;
      strong {
        font-weight: bold;
        font-size: 16px;
      }
      .el-divider {
        background: #960f23;
        width: 32px;
        height: 4px;
        margin: 20px 0;
      }
    }
  }
  .beian {
    padding-top: 30px;
  }
  a {
    color: #fff;
  }
}
.content {
  margin-left: 300px;
  // max-height: 550px;
}
.content::-webkit-scrollbar {
  display: none;
}
</style>
