<template>
  <div class="layout">
    <div class="fixed" id="fixed">
      <div class="nav-status flex-between-center">
        <a href="tel:021-61806588-8003" class="el-icon-phone tel"
          >021-61806588-8003</a
        >
        <div class="flex flex-col-center">
          <!-- <el-breadcrumb separator="|">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>网培师联盟</el-breadcrumb-item>
          <el-breadcrumb-item>在线留言</el-breadcrumb-item>
        </el-breadcrumb>-->
          <div v-if="login">
            <span><i class="el-icon-user"></i> {{ user.name }}</span>
            <span @click="link('UserInfo', 'info')" style="color: #409eff"
              >个人中心 <i class="el-icon-arrow-right"></i
            ></span>
            <!-- <span @click="logout">退出</span> -->
          </div>
          <div v-else>
            <el-button type="danger" size="small" @click="link('Login')"
              >登录</el-button
            >
            <el-button type="danger" size="small" @click="link('Regist')"
              >注册</el-button
            >
          </div>
        </div>
      </div>
      <div class="nav-status nav-cont flex-between-center">
        <span @click="toHome"
          ><img src="../../assets/images/logo.png" alt srcset
        /></span>
        <el-menu
          :default-active="curActive"
          class="navbar"
          mode="horizontal"
          :router="true"
          background-color=""
        >
          <el-menu-item index="/">首页</el-menu-item>
          <!-- <el-menu-item index="/news">行业资讯</el-menu-item> -->
          <el-submenu index="1">
            <template slot="title">认证体系</template>
            <el-menu-item index="/certificate">(初、中)网培师</el-menu-item>
            <el-menu-item index="/gcertificate">高级网培师</el-menu-item>
          </el-submenu>
          <!-- <el-menu-item index="/guide">报考指南</el-menu-item> -->
          <el-submenu index="2">
            <template slot="title">报考指南</template>
            <el-menu-item index="/guide">报考须知</el-menu-item>
            <el-menu-item index="/syllabus">考试大纲</el-menu-item>
            <el-menu-item index="/invigilator">智慧监考系统</el-menu-item>
          </el-submenu>
          <el-menu-item index="/traincourse">培训课程</el-menu-item>
          <el-submenu
            index="3"
            :default-active="curActive"
            class="navbar"
            mode="horizontal"
            :router="true"
          >
            <template slot="title">在线评测</template>
            <el-menu-item index="/evaluationnotes">评测须知</el-menu-item>
            <!-- <el-menu-item index="/traincourse">培训课程</el-menu-item> -->
            <el-menu-item index="/simulation">模拟练习</el-menu-item>
            <el-menu-item index="/examination">参加考试</el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">证书发放</template>
            <el-menu-item index="/certsearch">证书查询</el-menu-item>
            <el-menu-item index="/certinfo">证书介绍</el-menu-item>
          </el-submenu>
          <el-menu-item index="/people">名企人才库</el-menu-item>
        </el-menu>
      </div>
    </div>

    <div class="main">
      <router-view></router-view>
    </div>

    <div class="footer">
      <el-row type="flex" justify="space-between">
        <el-col :span="7">
          <div>
            <img
              src="../../assets/images/home/logo.png"
              alt
              srcset
              class="logo"
            />
          </div>
          <div class="qrcode_container">
            <div>
              <img
                src="../../assets/images/exam/qrcode.jpg"
                alt
                srcset
                class="qrcode_img qrcode1"
                preview="1"
              />
              <p>关注"网培师公众号"</p>
            </div>
            <div>
              <img
                src="../../assets/images/home/xxkc.png"
                alt
                srcset
                class="qrcode_img"
                preview="2"
              />
              <p>扫码前往培训课程</p>
            </div>
          </div>
        </el-col>
        <el-col :span="2">
          <p>
            <strong>项目介绍</strong>
            <el-divider></el-divider>
          </p>
          <p>
            <a href="/news">新闻公告</a>
          </p>
          <p>
            <a
              href="http://resource.wangpeishi.org.cn/evaluation-standard.pdf"
              target="_blank"
              >行业标准</a
            >
          </p>
          <p>
            <a href="certinfo">认证机构</a>
          </p>
        </el-col>
        <el-col :span="2">
          <p>
            <strong>认证体系</strong>
            <el-divider></el-divider>
          </p>
          <p>
            <a href="certificate">网培师</a>
          </p>
          <p>
            <a href="gcertificate">高级网培师</a>
          </p>
        </el-col>
        <el-col :span="5">
          <p>
            <strong>合作伙伴</strong>
            <el-divider></el-divider>
          </p>
          <el-tooltip placement="left-start" effect="light">
            <p style="cursor: pointer">上海人才服务行业协会</p>
            <div slot="content" class="tooltips">
              <h4>上海人才服务行业协会</h4>
              <p>联系人：陈老师</p>
              <p>电话：<a href="tel:13917188146"></a>13917188146</p>
              <!-- <p>地址：上海市梅园路77号上海人才大厦10层1011室</p> -->
            </div>
          </el-tooltip>
          <el-tooltip placement="left-start" effect="light">
            <p style="cursor: pointer">博锐利恩信息技术服务（常州）有限公司</p>
            <div slot="content" class="tooltips">
              <h4>博锐利恩信息技术服务（常州）有限公司</h4>
              <p>联系人：费老师</p>
              <p>电话：13815057797</p>
              <!-- <p>地址：江苏省常州市竹林北路256号天宁科技促进中心4楼456室</p> -->
            </div>
          </el-tooltip>
          <el-tooltip placement="left-start" effect="light">
            <p style="cursor: pointer">上海浦东新区东浦职业技能培训中心</p>
            <div slot="content" class="tooltips">
              <h4>上海浦东新区东浦职业技能培训中心</h4>
              <p>联系人：万老师</p>
              <p>电话：13818144434</p>
              <!-- <p>地址：江苏省常州市竹林北路256号天宁科技促进中心4楼456室</p> -->
            </div>
          </el-tooltip>
          <el-tooltip placement="left-start" effect="light">
            <p style="cursor: pointer">上海赛兰企业管理有限公司</p>
            <div slot="content" class="tooltips">
              <h4>上海赛兰企业管理有限公司</h4>
              <p>联系人：赵老师</p>
              <p>电话：18721237090</p>
              <!-- <p>地址：江苏省常州市竹林北路256号天宁科技促进中心4楼456室</p> -->
            </div>
          </el-tooltip>
        </el-col>
        <el-col :span="5">
          <p>
            <strong>联系我们</strong>
            <el-divider></el-divider>
          </p>
          <!-- <p>
            <a href="question">网培师网站QA常见问题</a>
          </p> -->
          <p>
            <a href="tel:19542727521">客服热线：19542727521</a>
          </p>
          <p>
            <a href="tel:021-61806577-8003">技术支持：021-61806577-8003</a>
          </p>
          <p>
            <a href="email: office@netban.cn">公司邮箱：office@netban.cn</a>
          </p>
          <p style="white-space:nowrap">
            办公地址：上海市静安区万荣路1188号A座龙软大厦6楼
          </p>
        </el-col>
      </el-row>
      <div class="text-center beian">
        <p>中国商业联合会 . 职业水平评价项目</p>
        <p style="margin: 5px 0 15px 0">
          <span>2020 网培师评测认证中心</span
          ><span style="margin-left: 15px">All Rights Reserved</span>
        </p>

        <img
          src="../../assets/images/public.png"
          alt=""
          style="vertical-align:middle"
        />
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006093"
        >
          沪公网安备 31010602006093号
        </a>
        <a href="https://beian.miit.gov.cn" style="padding-left:35px">
          沪ICP备10203489号-52
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Layout',
  components: {},
  data() {
    return {
      curActive: '/',
      user: {},
      login: false,
    }
  },
  mounted() {
    this.curActive = this.$route.path
  },
  created() {
    this.init()
  },
  watch: {
    $route(to) {
      if (to.path == '/') {
        this.init()
      }
    },
  },
  methods: {
    init() {
      this.user = this.$store.state.wps_user || null
      this.login = this.$store.state.wps_user.mobile ? true : false
    },
    link(name, id) {
      if (id) {
        this.$router.push({ name: name, params: { id: id } })
      } else {
        this.$router.push({ name: name })
      }
    },
    toHome() {
      this.$router.push({ path: '/' })
    },
    // logout() {
    //   this.$store.commit("setUser", {});
    //   this.Storage.delStorage('token');
    //   this.user = {};
    //   this.login = false;
    // },
  },
}
</script>
<style lang="less" scoped>
/deep/.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #960f23;
}
/deep/.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 2px solid #960f23;
}
.layout {
  min-width: 1200px;
}
.nav-status {
  width: 70%;
  min-width: 1200px;
  margin: 0 auto;
  font-size: 16px;
  padding: 12px 0;
  border-bottom: 1px solid #ddd;
  color: #333;
  .tel {
    color: #960f23;
    font-weight: bold;
    vertical-align: middle;
  }
  .el-button {
    background: #960f23;
    margin-left: 20px;
    &:visited,
    &:hover,
    &:active {
      background: #960f23;
    }
  }
  span {
    margin-right: 20px;
    cursor: pointer;
  }
  /deep/.el-breadcrumb {
    font-size: 16px;
    margin-right: 40px;
    .el-breadcrumb__inner {
      color: #333 !important;
    }
  }
  /deep/.navbar {
    border-bottom: none !important;
    .el-submenu__title {
      padding: 0 10px !important;
      color: #333 !important;
      font-size: 16px;
      i {
        display: none;
      }
    }
    .el-menu-item {
      font-size: 16px;
      color: #333 !important;
    }
    .el-menu-item {
      padding: 0px 10px;
    }
  }
}
.nav-cont {
  border-bottom: none;
}
.main {
  padding-top: 131px;
}
.footer {
  width: 100%;
  height: 380px;
  padding: 40px 0;
  background: #2b2b2b;
  box-sizing: border-box;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  .el-row {
    width: 70%;
    min-width: 1200px;
    margin: 0 auto;
    p {
      padding-bottom: 10px;
      strong {
        font-weight: bold;
        font-size: 16px;
      }
      .el-divider {
        background: #960f23;
        width: 32px;
        height: 4px;
        margin: 20px 0;
      }
    }
    .qrcode_container {
      display: flex;
      justify-content: space-around;
      width: 330px;
      position: relative;
      top: 40px;
      text-align: center;
      p {
        padding: 10px 0px 0px;
      }
    }
    .qrcode_img {
      width: 150px;
      height: 150px;
    }
  }
  // .beian {
  // padding-top: 30px;
  // }
  a {
    color: #fff;
  }
}
.fixed {
  position: fixed;
  width: 100%;
  // height: auto;
  background: #fff;
  overflow-y: auto;
  top: 0;
  z-index: 1024;
  box-shadow: 0 0 8px #e7e7e7;
}
.logo {
  width: 335px;
  height: 61px;
}
</style>
<style>
.el-tooltip__popper.is-light {
  box-shadow: none !important;
  /* max-width: 250px; */
}
.tooltips p {
  text-align: left;
  margin-top: 6px;
  padding-left: 40px;
  text-indent: -37px;
}
.tooltips h4 {
  font-size: 16px;
  margin-bottom: 12px;
  text-align: center;
}
</style>
